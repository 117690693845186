function generateSubmitForm(data) {
    const action = `${marketOrigin}/job_terminator`;
    
    var form = '<form name="myform" action="' + action + '" method="get" hidden="true">';
    for(const [key, val] of Object.entries(data)){
        form += '<input type="text" name="' + key + '" value="'+ val + '" />';
    }
    form += '</form>';

    return form;
}

let jobMessage = {};
let marketOrigin = '';

export default {
    platformName: () => ("TuttiMarket"),

    invokeWhenReady(handler) {
        window.addEventListener(
            'message',
            (message) => {
                if (Object.keys(message.data).includes('jobMessage')) {
                    marketOrigin = message.origin;
                    jobMessage = message.data.jobMessage;
                    handler();
                }
            },
        );
    },

    workerId: function() {
        return jobMessage.workerId;
    },

    clientToken: function() {
        return jobMessage.jobAssignmentId;
    },

    nanotaskGroupIds: function() {
        const jobParameter = jobMessage.jobParameter;
        return jobParameter ? jobParameter.nanotask_group_ids : null;
    },

    automationParameterSetId: function() {
        const jobParameter = jobMessage.jobParameter;
        return jobParameter ? jobParameter.automation_parameter_set_id: null;
    },

    platformParameterSetId: function() {
        const jobParameter = jobMessage.jobParameter;
        return jobParameter ? jobParameter.platform_parameter_set_id: null;
    },
    showWorkerMenu: false,

    onClientTokenFailure: function(a,b,c) {
        console.log("clienttokenFailure",a,b,c);
    },

    onWorkerIdNotFound: function(next, pn) {
        next({ path: `/workplace/${pn}/preview` });
        return false;
    },

    onSubmitWorkSession: function() {
        let data = {
            job_assignment_id: jobMessage.jobAssignmentId,
            worker_id: jobMessage.workerId
        };

        const form = generateSubmitForm(data);
        document.body.innerHTML += form;
        document.forms.myform.submit();
    }
}
