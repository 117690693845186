<template>
    <v-app>
        <v-main>
            <v-data-table
                :headers="headers"
                :items="logs"
                :items-per-page="10"
                disable-sort
                hide-default-footer>
                <template v-slot:item.msg="{ item }">
                    <v-icon small :color="getIconColorForLog(item)">{{ getIconForLog(item) }}</v-icon>
                    <b> {{ item.label }}</b>

                    <div
                        v-if="item.received[0]"
                        style="width:100%;">
                        {{ dateFormat(parseInt(item.sent.timestamp.getTime()), "yyyy-mm-dd HH:MM:ss") }}
                    </div>

                    <vue-json-pretty :data="removeAccessTokenFromSentContent(item.sent.content)" :deep="1"></vue-json-pretty>

                    <div style="display:flex;" v-for="received,i in item.received" :key="`received${item.id}-${i}`">
                        <div style="margin-right:5px;width:100%">
                            <v-icon x-small>mdi-arrow-right</v-icon>
                        </div>
                        <div style="width:100%">
                            <vue-json-pretty
                                :data="received.content"
                                :deep="0">
                            </vue-json-pretty>
                        </div>
                    </div>
                </template>
            </v-data-table>
        </v-main>
    </v-app>
</template>

<script>
import 'vue-json-pretty/lib/styles.css'
import VueJsonPretty from "vue-json-pretty/lib/vue-json-pretty"
import dateFormat from 'dateformat'
import tuttiWindowMessage from '@/lib/window-message'

export default {
    components: {
        VueJsonPretty
    },
    data: () => ({
        dateFormat,
        logs: [],
        headers: [
            { text: "Message", value: "msg" },
        ],
        clientEvents: {},
    }),
    props: ['client'],
    methods: {
        getIconForLog(item) {
            if(!item.received[0]) return 'mdi-clock-outline';
            else return item.received[0].success ? 'mdi-check-circle' : 'mdi-alert';
        },
        getIconColorForLog(item) {
            if(!item.received[0]) return 'warning';
            else return item.received[0].success ? 'success' : 'error';
        },
        removeAccessTokenFromSentContent(content) {
            let _content = JSON.parse(JSON.stringify(content));
            delete _content.access_token;
            return _content;
        }
    },
    created() {
        window.resizeTo(500, window.screen.availHeight);
        tuttiWindowMessage.setHandler((msg) => {
            if(msg.evt==='events') {
                this.clientEvents = msg.data;
            }
            else if(msg.evt==='logs') {
                this.logs = msg.data.slice().reverse();
            }
        });
        tuttiWindowMessage.send(window.opener, 'init', null);
    }
}
</script>
