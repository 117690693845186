<template>
    <div v-show="shown">
        <slot v-bind="{ show, hide }"></slot>
    </div>
</template>
<script>
export default {
    data: () => ({
        shown: false,
    }),
    props: {
        timeRanges: { type: Array, default: () => [] },
    },
    methods: {
        show() { this.shown = true; },
        hide() { this.shown = false; },
    },
    watch: {
        shown(shown) {
            if(shown) this.$emit('show');
            else  this.$emit('hide');
        }
    },
    mounted() {
        for(const range of this.timeRanges) {
            setTimeout(this.show, range[0]);
            if(range.length === 1) continue;
            setTimeout(this.hide, range[1]);
        }
    }
}
</script>