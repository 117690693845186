<!-- Your HTML code here -->
<template>   
    <div class="fill-height d-flex flex-column">
        <progress-bar :count="nano.props.count" :total-count="nano.props.totalCount"></progress-bar>
        <div class="flex-grow-1">
            <resource-load-progress v-if="!started"></resource-load-progress>

            <div v-if="started" class="card ma-auto fill-height d-flex align-center flex-column">
                <div class="d-flex align-center flex-grow-1" style="width: 100%">
                    <temporary-div class="fill-width text-center" :time-ranges="[ timeRanges.img1 ]">
                        <img :src="imgSrcs[0]" style="max-width: 500px;" />
                    </temporary-div>

                    <temporary-div
                        class="fill-width text-center"
                        :time-ranges="[ timeRanges.blank2 ]"
                        @show="imagining1 = true;"
                        @hide="imagining1 = false;"
                    >
                        <imagine-pause-progress v-if="imagining1" :total-seconds="(timeRanges.blank2[1]-timeRanges.blank2[0])/1000"></imagine-pause-progress>
                    </temporary-div>

                    <temporary-div class="fill-width text-center" :time-ranges="[ timeRanges.img2 ]">
                        <img :src="imgSrcs[1]" style="max-width: 500px;" />
                    </temporary-div>

                    <temporary-div
                        class="fill-width text-center"
                        :time-ranges="[ timeRanges.blank3 ]"
                        @show="imagining2 = true;"
                        @hide="imagining2 = false;"
                    >
                        <imagine-pause-progress v-if="imagining2" :total-seconds="(timeRanges.blank3[1]-timeRanges.blank3[0])/1000"></imagine-pause-progress>
                    </temporary-div>

                    <temporary-div
                        class="fill-width text-center"
                        :time-ranges="[ timeRanges.choices ]"
                        @show="startTime = Date.now();"
                    >
                        <p class="text-h6 font-weight-medium my-4">{{ question }}</p>
                        <div class="d-flex justify-space-between">
                            <v-card
                                v-for="i in [0,1]"
                                :key="`button-${i}`"
                                class="ma-4 text-center"
                                width="300"
                                @click="submitAnswer(i+1)"
                            >
                                <img :src="imgSrcs[i]" class="pa-4" style="width:100%" />
                                <p class="text-body font-weight-bold">{{  i===0 ? 'First' : 'Second' }} one</p>
                            </v-card>
                        </div>
                    </temporary-div>
                </div>
            </div>
        </div>
    </div>
</template>

<!-- Your JavaScript code here -->
<script>
import nanoMixIn from "@/mixins/nano";
import TemporaryDiv from '../../../01-ImageEvaluation-1/ui/TemporaryDiv.vue';
import ResourceLoadProgress from "../../../01-ImageEvaluation-1/ui/ResourceLoadProgress.vue";
import ImaginePauseProgress from "../../../01-ImageEvaluation-1/ui/ImaginePauseProgress.vue";
import ProgressBar from '../../../01-ImageEvaluation-1/ui/ProgressBar.vue';

export default {
    mixins: [nanoMixIn],
    components: { ProgressBar, TemporaryDiv, ResourceLoadProgress, ImaginePauseProgress },
    data: () => ({
        started: false,
        imagining1: false,
        imagining2: false,
        imgSrcs: [],
        startTime: null,
        question: 'Q. Which was more accurately imagined?',

        timeDurations: [
            ['blank1', 2000],
            ['img1', 2000],
            ['blank2', 8000],
            ['img2', 2000],
            ['blank3', 8000],
            ['choices', null]
        ],
        defaultNanoProps: {
            rrId: 0,
            order: 0,
            images: [
                {
                    path: 'samples/img1-1.png',
                    id: 't0',
                    rank: 10
                },
                {
                    path: 'samples/img2-1.png',
                    id: 'w3',
                    rank: 495
                }
            ],
            count: 4,
            totalCount: 10,
        },
        defaultNanoAnswers: {
            answer: null,
            elapsedSeconds: 0,
            count: null,
        }
    }),
    computed: {
        timeRanges() {
            let now = 0;
            return Object.fromEntries(this.timeDurations.map(([key, duration]) => {
                if(duration !== null) {
                    const range = [now, now + duration];
                    now += duration;
                    return [key, range];
                } else {
                    return [key, [now]];
                }
            }));
        }
    },
    methods: {
        submitAnswer(val) {
            this.nano.ans = { ...this.nano.ans, ...this.nano.props };
            this.nano.ans.answer = val;
            this.nano.ans.elapsedSeconds = (Date.now() - this.startTime)/1000;
            console.log(JSON.stringify(this.nano.ans));
            this.submit();
        }
    },
    async mounted() {
        console.log(JSON.stringify(this.nano.props));
        const loadImagePromise = (path) => {
            return new Promise((resolve, reject) => {
                let img = new Image();
                const fullPath = `/static/images/${path}`;
                img.onload = () => { resolve(fullPath); }
                img.onerror = () => { reject(); }
                img.src = fullPath;
            });
        };
        this.imgSrcs = [
            await loadImagePromise(this.nano.props.images[0].path),
            await loadImagePromise(this.nano.props.images[1].path)
        ]
        this.started = true;
    }
};
</script>

<style scoped>
.card { width: 100%; max-width: 800px; }
.fill-width { width: 100%; }
.radio-group { width: fit-content; display: block; }
.selected-choice {
    background-color: #bdb;
}
</style>
