<template>
    <div style="margin-top: 100px">
        <p class="ma-0 text-h5 text-center">
            What does the animal in the Target image below eat to grow up?
        </p>
        <p class="ma-0 text-h5 text-center">
            Find a proper word for the answer on the right and type it in the
            Answer form.
        </p>
        <div class="d-flex align-center justify-center" style="margin: 50px 0">
            <div class="mr-6">
                <p class="text-h5 mb-2 text-center">Target image</p>
                <img
                    class="image-size"
                    src="/static/qst-202302/screening/target.png"
                    style="height: 200px"
                />
            </div>
            <div>
                <p class="text-h5 mb-2 text-center">Text box</p>
                <img
                    class="image-size"
                    src="/static/qst-202302/screening/textbox.png"
                    style="height: 200px"
                />
            </div>
        </div>
        <div>
            <p class="text-h5 text-center my-8">Answer form</p>
            <div class="d-flex justify-center mx-auto" style="width: 600px">
                <v-text-field
                    v-model="wordInput"
                    outlined
                    style="font-size: 26px; width: 400px"
                    class="mx-auto"
                    hide-details
                ></v-text-field>
                <v-btn x-large class="mx-2" @click="$emit('confirm', wordInput)"
                    >Submit</v-btn
                >
            </div>
            <p class="text-h5 text-center my-8">
                Word can be entered up to 22 characters
            </p>
        </div>
    </div>
</template>
<script>
export default {
    data: () => ({
        wordInput: ''
    })
}
</script>
