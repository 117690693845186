var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        { staticClass: "mt-6", attrs: { justify: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "10" } },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [_vm._v(" Tutti Market ")]),
                  _vm._l(_vm.menuItems, function (item) {
                    return _c(
                      "div",
                      { key: item.title },
                      [
                        _c("v-divider"),
                        _c(
                          "v-card-text",
                          { staticClass: "d-flex" },
                          [
                            _c("div", { staticClass: "text-body-1" }, [
                              _vm._v(" " + _vm._s(item.title) + " "),
                            ]),
                            _c("v-spacer"),
                            _c("v-btn", [_vm._v(" Go ")]),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }