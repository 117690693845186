<template>
    <v-main>
        <query-nav-bar :client="client" :events="events"></query-nav-bar>
        <query-log-table :logs="queryLogs"></query-log-table>
    </v-main>
</template>

<script>
import QueryNavBar from './QueryNavBar'
import QueryLogTable from './QueryLogTable'

export default {
    components: {
        QueryNavBar,
        QueryLogTable
    },
    data: () => ({
        events: [],
        queryLogs: []
    }),
    props: ['client'],
    methods: {
        loadEvents() {
            var events = []
            for(var key in this.client.EVENT) {
                var eid = this.client.EVENT[key]
                if(eid>=1000){ events.push({eid, label: `${eid}:: ${key}`}) }
            }
            this.events = events
        },
    },
    mounted() {
        this.client.invokeOnOpen(() => {
            this.client.resource.on('getEventHistory', {
                success: (data) => {
                    this.queryHistoryAll = data["AllHistory"];
                }
            });
            this.client.resource.on('setEventHistory', {
                success: (data) => {
                    this.$set(this.queryHistoryAll, data["EventId"], data["History"].reverse());
                }
            });

            this.loadEvents();
            this.client.resource.getEventHistory.send();

            setInterval(() => {
                var logs = [];
                const clientLog = this.client.logger.log;
                for(const rid in clientLog){
                    if( clientLog[rid].eid <= 1010 )  continue;

                    logs.unshift({
                        rid: rid,
                        eid: `${Object.keys(this.client.EVENT).find(key => this.client.EVENT[key] === clientLog[rid].eid)} (${clientLog[rid].eid})`,
                        sent: clientLog[rid].sent,
                        received: clientLog[rid].received[0]
                    });
                }
                this.queryLogs = logs;
            }, 1000);
        });
    }
}
</script>
