<template>
    <div class="pa-4 mx-auto" style="max-width: 800px">
        <p class="text-h4">About This HIT</p>
        <ul>
            <li>You will be asked simple questions to evaluate 15 pairs of pictures in a row.</li>
            <li>Estimated time: 10 minutes.</li>
        </ul>
        <p class="text-h4 mt-4">Instruction</p>
        <ol>
            <li>A blank screen is shown for 2 seconds.</li>
            <li>
                Look at a suggested picture for 2 seconds, as shown like below:<br>
                <img src="/static/202212/sample/img1-1.png" style="width:100px" />
            </li>
            <li>
                Given 8 seconds, try to re-imagine the picture in your mind.<br>
            </li>
            <li>
                Look at a different picture for 2 seconds. For example:<br>
                <img src="/static/202212/sample/img2-1.png" style="width:100px" />
            </li>
            <li>
                Given 8 seconds, try to re-imagine the picture in your mind.<br>
            </li>
            <li>
                Answer which picture was more accurately imagined.
                <div class="d-flex justify-space-between">
                    <v-card class="ma-4 text-center" width="300" @click="radioValue = 1;">
                        <img src="/static/202212/sample/img1-1.png" class="pa-4" style="width:100%" />
                        <p class="text-body font-weight-bold">First one</p>
                    </v-card>

                    <v-card class="ma-4 text-center" width="300" @click="radioValue = 2;">
                        <img src="/static/202212/sample/img2-1.png" class="pa-4" style="width:100%" />
                        <p class="text-body font-weight-bold">Second one</p>
                    </v-card>
                </div>
            </li>
        </ol>
    </div>
</template>
<script>
export default {
    data: () => ({
        radioValue: 1
    })
}
</script>
<style scoped>
span.caution { color: #c00; }
.selected-choice {
    background-color: #bdb;
}
</style>

