<template>
    <v-main class="grey lighten-4">
        <div v-if="signedIn">
            <v-system-bar color="success" style="color:white;">
                Currently signed in as&nbsp;<b>{{ user.id }}</b>&nbsp;to&nbsp;<b>{{ market.wsdUrl }}</b>
            </v-system-bar>
        </div>
        <div v-else>
            <v-system-bar color="grey lighten-2">
                <span v-if="loading">
                    Loading credentials...
                    <v-btn icon disabled loading></v-btn>
                </span>

                <span v-else>
                    No valid credential is currently set
                </span>
            </v-system-bar>
        </div>

        <keep-alive :exclude="['HIT-Create']">
            <router-view
                :client="client"
                :projectName="projectName"
                :market="market"
                :user="user"
                />
        </keep-alive>
    </v-main>
</template>
<script>
import market from '@/lib/market/ducts.js'

const getUserDefault = () => ({
    id: '',
    accountInfo: null
});

export default {
    data: () => ({
        market,
        loading: false,
        user: getUserDefault(),
    }),
    props: ["client", "projectName"],
    computed: {
        signedIn() {
            return this.user.id.length>0 && this.user.accountInfo!==null;
        }
    },
    methods: {
        async updateUser() {
            console.log(this.user);
            if(!(await this.market.isAccessTokenExpired())){
                this.user.id = await this.market.getUserId();
                this.user.accountInfo = await this.market.getAccountInfo();
            } else {
                this.$set(this, 'user', getUserDefault());
            }
        },
        async connectToMarket(host) {
            this.market.wsdUrl = host+'/ducts/wsd';
            await this.updateUser();
        }
    },
    async mounted() {
        ['sign-in', 'sign-out'].forEach((evt) => { this.$root.$on(evt, async () => { await this.updateUser(); }); });
        this.$root.$on('update-host', this.connectToMarket);

        const host = window.localStorage.getItem('tuttiMarketHost') || undefined
        if(host) this.connectToMarket(host);
    }
}
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.5s;
  transition-property: opacity;
  transition-timing-function: ease-in;
}

.fade-enter-active {
  transition-duration: 0.5s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}
</style>
