<template>
    <div class="mt-4">
        <v-slider
            :value="1"
            readonly
            tick-size="2"
            ticks="always"
            :tick-labels="[1,2,3,4,5]"
            min="1"
            max="5"
            color="transparent"
        >
            <template v-slot:prepend>
                <div class="mt-9 text-right" style="width:50px;">
                    Low
                </div>
            </template> 
            <template v-slot:append>
                <div class="mt-9" style="width:50px;">
                    High
                </div>
            </template> 
        </v-slider>
        <div class="d-flex">
            <div style="width:61px;"></div>
            <div class="flex-grow-1">
                <v-radio-group hide-details :readonly="readonly" class="mt-2" @change="emitEvents">
                    <div class="d-flex justify-space-between mx-1">
                        <v-radio :value="1"></v-radio>
                        <v-radio :value="2"></v-radio>
                        <v-radio :value="3"></v-radio>
                        <v-radio :value="4"></v-radio>
                        <v-radio :value="5"></v-radio>
                    </div>
                </v-radio-group>
            </div>
            <div style="width:61px"></div>
        </div>
    </div>
</template>
<script>
export default {
    data: () => ({
        value: null,
    }),
    props: ['readonly'],
    methods: {
        emitEvents(value) {
            this.$emit('input', value);
            this.$emit('change', value);
        }
    }
}
</script>
<style>
    .v-slider__tick {
        width: 2px!important;
        height: 20px!important;
        top: calc(50% - 10px)!important;
        background-color: #8ac5ff !important;
    }
    .v-slider__tick:nth-child(2n+1) {
        height: 30px!important;
        top: calc(50% - 15px)!important;
    }
    .v-slider__tick-label { top: 30px!important; }
    .v-slider__tick:nth-child(2n+1) > .v-slider__tick-label {
        top: 35px!important;
    }
    .v-slider__tick-label:first-child, .v-slider__tick-label:last-child {
        transform: translateX(-50%)!important;
    }
    .v-input--selection-controls__input {
        margin-right: 0!important;
    }
    .v-input__prepend-outer, .v-input__append-outer {
        margin: 0!important;
        padding: 2px 10px!important;
    }
</style>