var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticStyle: { "margin-top": "100px" } }, [
    _c("p", { staticClass: "ma-0 text-h5 text-center" }, [
      _vm._v(
        " What does the animal in the Target image below eat to grow up? "
      ),
    ]),
    _c("p", { staticClass: "ma-0 text-h5 text-center" }, [
      _vm._v(
        " Find a proper word for the answer on the right and type it in the Answer form. "
      ),
    ]),
    _vm._m(0),
    _c("div", [
      _c("p", { staticClass: "text-h5 text-center my-8" }, [
        _vm._v("Answer form"),
      ]),
      _c(
        "div",
        {
          staticClass: "d-flex justify-center mx-auto",
          staticStyle: { width: "600px" },
        },
        [
          _c("v-text-field", {
            staticClass: "mx-auto",
            staticStyle: { "font-size": "26px", width: "400px" },
            attrs: { outlined: "", "hide-details": "" },
            model: {
              value: _vm.wordInput,
              callback: function ($$v) {
                _vm.wordInput = $$v
              },
              expression: "wordInput",
            },
          }),
          _c(
            "v-btn",
            {
              staticClass: "mx-2",
              attrs: { "x-large": "" },
              on: {
                click: function ($event) {
                  return _vm.$emit("confirm", _vm.wordInput)
                },
              },
            },
            [_vm._v("Submit")]
          ),
        ],
        1
      ),
      _c("p", { staticClass: "text-h5 text-center my-8" }, [
        _vm._v(" Word can be entered up to 22 characters "),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "d-flex align-center justify-center",
        staticStyle: { margin: "50px 0" },
      },
      [
        _c("div", { staticClass: "mr-6" }, [
          _c("p", { staticClass: "text-h5 mb-2 text-center" }, [
            _vm._v("Target image"),
          ]),
          _c("img", {
            staticClass: "image-size",
            staticStyle: { height: "200px" },
            attrs: { src: "/static/qst-202302/screening/target.png" },
          }),
        ]),
        _c("div", [
          _c("p", { staticClass: "text-h5 mb-2 text-center" }, [
            _vm._v("Text box"),
          ]),
          _c("img", {
            staticClass: "image-size",
            staticStyle: { height: "200px" },
            attrs: { src: "/static/qst-202302/screening/textbox.png" },
          }),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }