var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    { staticClass: "grey lighten-4" },
    [
      _c(
        "v-card",
        { staticClass: "pa-3 ma-10", attrs: { tile: "" } },
        [
          _c("v-card-title", [
            _vm._v(" " + _vm._s(_vm.$t("console.versionLogs.title")) + " "),
          ]),
          _c("v-simple-table", [
            _c(
              "tbody",
              [
                _c("tr", [
                  _c("th", [
                    _vm._v(
                      _vm._s(_vm.$t("console.versionLogs.columns.version"))
                    ),
                  ]),
                  _c("th", [
                    _vm._v(
                      _vm._s(_vm.$t("console.versionLogs.columns.dateAndTime"))
                    ),
                  ]),
                  _c("th", [
                    _vm._v(
                      _vm._s(_vm.$t("console.versionLogs.columns.details"))
                    ),
                  ]),
                ]),
                _vm._l(_vm.logs, function (log) {
                  return _c("tr", { key: log.version }, [
                    _c("td", [_vm._v(_vm._s(log.version))]),
                    _c("td", [_vm._v(_vm._s(log.date))]),
                    _c(
                      "td",
                      { staticClass: "py-2" },
                      _vm._l(Object.keys(log.messages), function (key) {
                        return _c(
                          "div",
                          { key: `${log.version}-${key}` },
                          [
                            _c("v-chip", {
                              staticClass: "my-1",
                              attrs: {
                                dark: "",
                                small: "",
                                color: _vm.logTypes[key].color,
                              },
                              domProps: {
                                textContent: _vm._s(_vm.logTypes[key].label),
                              },
                            }),
                            _c(
                              "ul",
                              _vm._l(log.messages[key], function (msg, i) {
                                return _c("li", {
                                  key: `${log.version}-${key}-${i}`,
                                  domProps: { innerHTML: _vm._s(msg) },
                                })
                              }),
                              0
                            ),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                  ])
                }),
              ],
              2
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }