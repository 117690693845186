<template>
    <div class="text-center">
        <div
            v-if="schemeConfig.ShowTitle"
            cols="12"
            class="text-h3 my-3">
            {{ schemeConfig.Title }}
        </div>

        <tutti-dialog ref="dialogInstruction" maxWidth="1000"
            :buttons="[
                {
                    label: 'Close',
                    attrs: { color: 'green darken-1', text: true },
                    on: { click() { dialogInstruction.hide(); } }
                }
            ]">
            <template #activator="{ on, attrs }">
                <v-btn
                    v-if="schemeConfig.InstructionBtn"
                    v-on="on"
                    v-bind="attrs"
                    class="mb-8"
                >
                    Show Instruction
                </v-btn>
            </template>
            <template #body>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn icon text class="mt-n4" @click="dialogInstruction.hide()"><v-icon>mdi-close</v-icon></v-btn>
                </v-card-actions>
                <div class="px-3">
                    <component :is="instructionTemplate" />
                </div>
            </template>
        </tutti-dialog>
    </div>
</template>

<script>
import TuttiDialog from '@/components/ui/TuttiDialog'
export default {
    components: {
        TuttiDialog
    },
    props: ['projectName', 'schemeConfig'],
    computed: {
        instructionTemplate() {
            try { return require(`@/projects/${this.projectName}/templates/Instruction.vue`).default }
            catch { return null }
        },
        dialogInstruction() {
            return this.$refs.dialogInstruction;
        }
    }
}
</script>
