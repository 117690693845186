var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    { staticClass: "grey lighten-4" },
    [
      _c(
        "div",
        [
          _c(
            "v-toolbar",
            [
              _c(
                "v-row",
                { attrs: { justify: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "8" } },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.registeredPlatforms,
                          "hide-details": "",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "keep-alive",
        { attrs: { exclude: ["HIT-Create"] } },
        [
          _c("router-view", {
            attrs: {
              duct: _vm.duct,
              prjName: _vm.prjName,
              credentials: _vm.crd,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }