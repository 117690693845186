var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "text-right ma-6" },
    [
      _vm.showWorkerMenu && _vm.platformWorkerId
        ? _c(
            "v-menu",
            {
              attrs: { "offset-y": "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function ({ on, attrs }) {
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            _vm._b(
                              {
                                staticClass: "text-none",
                                attrs: { text: "", color: "indigo" },
                              },
                              "v-btn",
                              attrs,
                              false
                            ),
                            on
                          ),
                          [
                            _vm._v(
                              " Worker ID: " +
                                _vm._s(_vm.platformWorkerId) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                181193631
              ),
            },
            [
              _c(
                "v-list",
                [
                  _c(
                    "v-list-item",
                    {
                      key: "logout",
                      on: {
                        click: function ($event) {
                          return _vm.$refs.dialog.show()
                        },
                      },
                    },
                    [_c("v-list-item-title", [_vm._v("Log out")])],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.showWorkerMenu && !_vm.platformWorkerId
        ? _c(
            "v-btn",
            {
              attrs: {
                dark: "",
                color: "indigo",
                href: `../workplace-login?project=${_vm.projectName}`,
              },
            },
            [_vm._v(" Log in ")]
          )
        : _vm._e(),
      _c("tutti-dialog", {
        ref: "dialog",
        attrs: {
          maxWidth: "500",
          buttons: [
            { label: "Cancel", attrs: { color: "grey darken-1", text: true } },
            {
              label: "Logout",
              attrs: { color: "indigo darken-1", text: true },
              on: { click: _vm.logout },
            },
          ],
        },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [_vm._v(" Are you sure to log out? ")]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c("v-card-text", [
                  _vm._v(" Some of your working history may not be saved. "),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }