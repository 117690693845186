var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "fill-height d-flex flex-column align-center justify-center",
    },
    [
      _c("v-progress-circular", {
        attrs: { size: 70, color: "grey", indeterminate: "" },
      }),
      _c("p", { staticClass: "py-4" }, [_vm._v("Loading resources...")]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }