var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      !_vm.schemeConfig.Anonymous
        ? _c("wp-login-button", {
            attrs: {
              projectName: _vm.projectName,
              showWorkerMenu: _vm.showWorkerMenu,
              platformWorkerId: _vm.platformWorkerId,
            },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "d-flex flex-column", staticStyle: { height: "100%" } },
        [
          _c("wp-header", {
            ref: "header",
            attrs: {
              "project-name": _vm.projectName,
              schemeConfig: _vm.schemeConfig,
            },
          }),
          _c(
            "div",
            { staticClass: "flex-grow-1" },
            [
              _c(
                "v-overlay",
                {
                  attrs: {
                    color: "white",
                    opacity: 0.6,
                    absolute: "",
                    value: _vm.loadingNextTemplate,
                  },
                },
                [
                  _c("v-progress-circular", {
                    attrs: { color: "grey", indeterminate: "", size: "64" },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "d-flex flex-column",
                  staticStyle: { height: "100%" },
                },
                [
                  _vm.schemeConfig.PageNavigation
                    ? _c(
                        "div",
                        [
                          _c(
                            "v-col",
                            { staticClass: "text-center" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mx-4 pa-2",
                                  attrs: {
                                    color: "white",
                                    disabled: !_vm.hasPrevTemplate,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getTemplate("PREV")
                                    },
                                  },
                                },
                                [_c("v-icon", [_vm._v("mdi-chevron-left")])],
                                1
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mx-4 pa-2",
                                  attrs: {
                                    color: "white",
                                    disabled: !_vm.hasNextTemplate,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getTemplate("NEXT")
                                    },
                                  },
                                },
                                [_c("v-icon", [_vm._v("mdi-chevron-right")])],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "flex-grow-1" },
                    [
                      _vm.showTemplate
                        ? [
                            _c(
                              "v-slide-x-reverse-transition",
                              { attrs: { "hide-on-leave": "" } },
                              [
                                _c(_vm.tmplComponent, {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.tmplComponent,
                                      expression: "tmplComponent",
                                    },
                                  ],
                                  key: "tmplComponent" + _vm.componentCounter,
                                  tag: "component",
                                  attrs: {
                                    "nano-props": _vm.nanoProps,
                                    "prev-answer": _vm.prevAnswer,
                                    "worker-id": _vm.workerId,
                                    "nano-mix-in-file-upload-queue":
                                      _vm.nanoMixInFileUploadQueue,
                                  },
                                  on: {
                                    "update:nanoMixInFileUploadQueue":
                                      function ($event) {
                                        _vm.nanoMixInFileUploadQueue = $event
                                      },
                                    "update:nano-mix-in-file-upload-queue":
                                      function ($event) {
                                        _vm.nanoMixInFileUploadQueue = $event
                                      },
                                    "upload-file": _vm.uploadFile,
                                    submit: _vm.submit,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-slide-x-reverse-transition",
                              { attrs: { "hide-on-leave": "" } },
                              [
                                _c("iframe", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.tmplHTML,
                                      expression: "tmplHTML",
                                    },
                                  ],
                                  ref: "iframe",
                                  staticStyle: { width: "100%", border: "0" },
                                  attrs: { srcdoc: _vm.tmplHTML },
                                }),
                              ]
                            ),
                          ]
                        : _vm._e(),
                      !_vm.showTemplate && _vm.showPreviewTemplate
                        ? [
                            _vm.schemeConfig.Anonymous
                              ? _c(
                                  "v-btn",
                                  {
                                    attrs: { color: "indigo" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.anonymousLogin()
                                      },
                                    },
                                  },
                                  [_vm._v(" Start Task ")]
                                )
                              : _vm._e(),
                            _c(_vm.previewTemplate, { tag: "component" }),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("tutti-dialog", {
        ref: "dialogAdviseReturn",
        attrs: {
          maxWidth: "800",
          buttons: [
            {
              label: "OK",
              attrs: { color: "grey darken-1", text: true },
              on: {
                click() {
                  _vm.$refs.dialogAdviseReturn.hide()
                },
              },
            },
          ],
        },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [
                _c(
                  "v-icon",
                  { staticClass: "mr-2", attrs: { color: "warning" } },
                  [_vm._v("mdi-alert")]
                ),
                _vm._v(" No more task is currently available "),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c("v-card-text", [
                  _vm._v(
                    " Thank you for your interest in this HIT! We are sorry but there is no more task available for you on this HIT for now."
                  ),
                  _c("br"),
                  _vm._v(
                    " Please return this HIT (nothing else will happen while this page is open). "
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("tutti-dialog", {
        ref: "dialogUnskippableNode",
        attrs: {
          persistent: "",
          maxWidth: "800",
          buttons: [
            {
              label: "OK",
              attrs: { color: "success", dark: true },
              on: { click: _vm._onSubmitWorkSession },
            },
          ],
        },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [
                _c(
                  "v-icon",
                  { staticClass: "mr-2", attrs: { color: "success" } },
                  [_vm._v("mdi-check-circle")]
                ),
                _vm._v(" You reached the end of this HIT "),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c("v-card-text", [
                  _vm._v(
                    " You reached the end of this task now; it might have been earlier than expected, but don't worry, you will still earn the same amount of reward."
                  ),
                  _c("br"),
                  _vm._v(
                    " This HIT will be automatically submitted as you close this dialog. "
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("tutti-dialog", {
        ref: "dialogSessionError",
        attrs: {
          maxWidth: "500",
          buttons: [
            {
              label: "OK",
              attrs: { color: "grey darken-1", text: true },
              on: {
                click() {
                  _vm.$refs.dialogSessionError.hide()
                },
              },
            },
          ],
        },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [
                _c(
                  "v-icon",
                  { staticClass: "mr-2", attrs: { color: "warning" } },
                  [_vm._v("mdi-alert")]
                ),
                _vm._v(" Please do one HIT at a time "),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c("v-card-text", [
                  _vm._v(
                    " Multiple concurrent sessions are not allowed for this HIT. Please finish the other HIT first."
                  ),
                  _c("br"),
                  _vm._v(
                    " If you believe this is caused in error, please try again later or contact "
                  ),
                  _c(
                    "a",
                    { attrs: { href: "mailto:mturk04@pcl.cs.waseda.ac.jp" } },
                    [_vm._v("mturk04@pcl.cs.waseda.ac.jp")]
                  ),
                  _vm._v(". "),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("tutti-dialog", {
        ref: "dialogCompleted",
        attrs: {
          persistent: "",
          maxWidth: "500",
          buttons: [
            {
              label: "OK",
              attrs: { color: "indigo darken-w", dark: true },
              on: { click: _vm._onSubmitWorkSession },
            },
          ],
        },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [
                _c(
                  "v-icon",
                  { staticClass: "mr-2", attrs: { color: "success" } },
                  [_vm._v("mdi-check-circle")]
                ),
                _vm._v(" Task Completed! "),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c("v-card-text", [
                  _vm._v(
                    " You finished all of our questions. Thank you for your contribution! "
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }