var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Instruction"),
      _c(
        "div",
        { staticClass: "text-center my-8" },
        [
          _c(
            "v-btn",
            {
              attrs: { large: "", color: "primary" },
              on: {
                click: function ($event) {
                  return _vm.submit()
                },
              },
            },
            [
              _vm._v("Start"),
              _c("v-icon", { attrs: { right: "" } }, [_vm._v("mdi-send")]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }