<template>   
    <div class="fill-height d-flex flex-column">
        <progress-bar :count="nano.props.count" :total-count="nano.props.totalCount"></progress-bar>
        <div class="flex-grow-1">
            <resource-load-progress v-if="!loaded"></resource-load-progress>

            <div v-if="loaded && !started" class="fill-height d-flex flex-column align-center justify-center">
                <v-btn large color="primary" @click="started = true;">Start</v-btn>
            </div>

            <div v-if="started" class="card ma-auto fill-height d-flex align-center flex-column">
                <div class="d-flex align-center flex-grow-1" style="width: 100%">
                    <temporary-div
                        class="fill-width text-center"
                        :time-ranges="[ timeRanges.img ]"
                    >
                        <img :src="imgSrc" style="max-width: 300px;" />
                    </temporary-div>

                    <temporary-div
                        class="fill-width text-center"
                        :time-ranges="[ timeRanges.blank ]"
                        @show="imagining = true;"
                    ></temporary-div>

                    <temporary-div
                        class="fill-width text-center"
                        :time-ranges="[ timeRanges.choices ]"
                        @show="startTime = Date.now();"
                    >
                        <img :src="imgSrc" style="max-width: 300px;" />
                        <radio-slider
                            v-model="nano.ans.answer"
                            :readonly="sliderReadOnly"
                            @change="delayedSubmit()"
                        ></radio-slider>
                    </temporary-div>
                </div>  
            </div>
        </div>
    </div>
</template>

<script>   
import nanoMixIn from "@/mixins/nano";
import TemporaryDiv from '../../ui/TemporaryDiv.vue';
import ResourceLoadProgress from "../../ui/ResourceLoadProgress.vue";
import RadioSlider from '../../ui/RadioSlider.vue';
import ProgressBar from '../../ui/ProgressBar.vue';

export default {
    mixins: [nanoMixIn],
    components: { ProgressBar, TemporaryDiv, ResourceLoadProgress, RadioSlider },
    data: () => ({
        loaded: false,
        started: false,
        imagining: false,
        imgSrc: '',
        startTime: null,
        sliderReadOnly: false,
        timeDurations: [
            ['img', 4000],
            ['blank', 8000],
            ['choices', null]
        ],

        defaultNanoProps: {
            image: {
                path: 'samples/img1-1.png',
                id: 0,
                rank: 10
            },
            count: 4,
            totalCount: 10,
        },
        defaultNanoAnswer: {
            answer: null,
            elapsedSeconds: 0,
            count: null,
        }
    }),
    computed: {
        timeRanges() {
            let now = 0;
            return Object.fromEntries(this.timeDurations.map(([key, duration]) => {
                if(duration !== null) {
                    const range = [now, now + duration];
                    now += duration;
                    return [key, range];
                } else {
                    return [key, [now]];
                }
            }));
        }
    },
    methods: {
        delayedSubmit(msec = 500) {
            this.sliderReadOnly = true;
            this.nano.ans = { ...this.nano.ans, ...this.nano.props };
            this.nano.ans.elapsedSeconds = (Date.now() - this.startTime)/1000;
            console.log(JSON.stringify(this.nano.ans));
            setTimeout(this.submit, msec);
        }
    },
    mounted() {
        let img = new Image();
        img.onload = () => {
            this.imgSrc = img.src;
            this.loaded = true;
        }
        const fullPath = `/static/images/${this.nano.props.image.path}`;
        img.src = fullPath;
    }
};
</script>

<style scoped>
.card { width: 100%; max-width: 800px; }
.fill-width { width: 100%; }
</style>
