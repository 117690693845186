var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Instruction"),
      _c(
        "div",
        { staticClass: "text-center mb-8" },
        [
          _c(
            "v-btn",
            {
              staticClass: "mb-8",
              attrs: { large: "" },
              on: {
                click: function ($event) {
                  return _vm.submit()
                },
              },
            },
            [
              _c("v-icon", { attrs: { left: "" } }, [_vm._v("mdi-check")]),
              _vm._v("I understood above"),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }