var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex flex-column" },
    [
      _c(
        "v-progress-circular",
        {
          staticClass: "ma-auto",
          attrs: {
            rotate: -90,
            size: 100,
            width: 15,
            color: "primary",
            value: (100 / _vm.totalSeconds) * _vm.secondsElapsed,
          },
        },
        [_vm._v(_vm._s(_vm.secondsElapsed))]
      ),
      _c("p", { staticClass: "py-4" }, [
        _vm._v("Now re-imagine what you just saw..."),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }