<template>
    <div class="fill-height d-flex flex-column">
        <progress-bar :count="nano.props.count" :total-count="nano.props.totalCount"></progress-bar>

        <div class="fill-height fill-width d-flex align-center">
            <div class="ma-auto text-center">
                <p class="text-h6 font-weight-medium my-4">{{ nano.props.quiz }}</p>
                <v-radio-group
                    v-model="nano.ans.answer"
                    class="radio-group mx-auto"
                    :readonly="readonly"
                    @change="readonly = true; submitAfterAWhile()"
                >
                    <v-radio value="Yes" label="Yes"></v-radio>
                    <v-radio value="No" label="No"></v-radio>
                </v-radio-group>
            </div>
        </div>
    </div>
</template>
<script>
import nanoMixIn from "@/mixins/nano";
import ProgressBar from '../../ui/ProgressBar.vue';
export default {
    mixins: [nanoMixIn],
    components: { ProgressBar },
    data: () => ({
        readonly: false,
        startTime: null,
        defaultNanoProps: {
            quiz: 'Was there an animal in the image?',

            count: 4,
            totalCount: 10,
        },
        defaultNanoAnswers: {
            answer: null,
        }
    }),
    methods: {
        submitAfterAWhile(msec = 500) {
            this.nano.ans = { ...this.nano.ans, ...this.nano.props };
            this.nano.ans.elapsedSeconds = (Date.now() - this.startTime)/1000;
            setTimeout(this.submit, msec);
        }
    },
    mounted() {
        this.startTime = new Date();
    }
};
</script>
<style scoped>
.fill-width { width: 100%; }
</style>