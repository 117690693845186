var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    { staticClass: "grey lighten-4" },
    [
      _vm.signedIn
        ? _c(
            "div",
            [
              _c(
                "v-system-bar",
                {
                  staticStyle: { color: "white" },
                  attrs: { color: "success" },
                },
                [
                  _vm._v(" Currently signed in as "),
                  _c("b", [_vm._v(_vm._s(_vm.user.id))]),
                  _vm._v(" to "),
                  _c("b", [_vm._v(_vm._s(_vm.market.wsdUrl))]),
                ]
              ),
            ],
            1
          )
        : _c(
            "div",
            [
              _c("v-system-bar", { attrs: { color: "grey lighten-2" } }, [
                _vm.loading
                  ? _c(
                      "span",
                      [
                        _vm._v(" Loading credentials... "),
                        _c("v-btn", {
                          attrs: { icon: "", disabled: "", loading: "" },
                        }),
                      ],
                      1
                    )
                  : _c("span", [
                      _vm._v(" No valid credential is currently set "),
                    ]),
              ]),
            ],
            1
          ),
      _c(
        "keep-alive",
        { attrs: { exclude: ["HIT-Create"] } },
        [
          _c("router-view", {
            attrs: {
              client: _vm.client,
              projectName: _vm.projectName,
              market: _vm.market,
              user: _vm.user,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }