<template>
    <div class="fill-height d-flex flex-column align-center justify-center">
        <temporary-div
            :timeRanges="[[firstBlankMilliSeconds]]"
            @show="startTimer"
            style="width: 100%"
        >
            <div class="pa-4">
                <img :src="imgSrcs[0][1]" class="d-block image-size mx-auto" />
            </div>

            <v-card style="width: min-content" class="mx-auto px-6">
                <v-card-text>
                    <p class="text-h4 text-center black--text">Answer</p>
                    <div class="d-flex align-center justify-center">
                        <img
                            class="pa-2 image-size"
                            style="cursor: pointer"
                            :src="imgSrcs[1][1]"
                            @click="
                                selectedPos = 0
                                stopTimer()
                            "
                        />
                        <p class="text-h5 px-4 black--text">or</p>
                        <img
                            class="pa-2 image-size"
                            style="cursor: pointer"
                            :src="imgSrcs[2][1]"
                            @click="
                                selectedPos = 1
                                stopTimer()
                            "
                        />
                    </div>
                </v-card-text>
            </v-card>
        </temporary-div>
    </div>
</template>

<script>
import TemporaryDiv from '../../ui/TemporaryDiv'

export default {
    components: { TemporaryDiv },
    data: () => ({
        firstBlankMilliSecondsDefault: 1000,
        startTime: null,
        finishTime: null,
        selectedPos: null
    }),
    props: {
        imgSrcs: {},
        showFirstBlank: { default: true }
    },
    computed: {
        firstBlankMilliSeconds() {
            return this.showFirstBlank ? this.firstBlankMilliSecondsDefault : 0
        },
        responseTime() {
            if (this.startTime === null || this.finishTime === null) {
                return null
            }
            return this.finishTime - this.startTime
        }
    },
    methods: {
        startTimer() {
            this.startTime = new Date()
        },
        stopTimer() {
            this.finishTime = new Date()
            this.$nextTick(() => {
                this.$emit('image-select', this.selectedPos, this.responseTime)
            })
        }
    }
}
</script>

<style scoped>
.card {
    width: 100%;
    max-width: 800px;
}
.image-size {
    height: 250px;
    max-height: 100%;
}
</style>
