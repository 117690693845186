<template>
    <div>
        <Instruction></Instruction>
        <div class="text-center my-8">
            <v-btn large color="primary" @click="submit()">Start<v-icon right>mdi-send</v-icon></v-btn>
        </div>
    </div>
</template>
<script>
import Instruction from './Base.vue'
import nanoMixIn from "@/mixins/nano";
export default {
    mixins: [nanoMixIn],
    components: { Instruction },
}
</script>