const requiredConfigs = [
    'platformName',
    'workerId',
    'clientToken',
    'showWorkerMenu',
    'onClientTokenFailure',
    'onWorkerIdNotFound',
    'onSubmitWorkSession',
];
const configDefaults = {
    invokeWhenReady(handler) { handler(); },
    nanotaskGroupIds() { return null; },
    automationParameterSetId() { return null; },
    platformParameterSetId() { return null; },
}

function importConfigs(name) {
    const configs = require(`./platform-configs/${name}.js`).default;
    const missingConfigs = requiredConfigs.filter((c) => { !Object.keys(configs).includes(c) });
    if(missingConfigs.length>0)
        throw new Error(`[${missingConfigs}] were not found in platformConfigs for '${name}'`);
    else
        return configs;
}

const rfr = document.referrer;

const conds = [
    ['tutti-market', (
        rfr.length>0 &&
        rfr.startsWith("https://teai.tutti.market")
    )],

    ['mturk', (
        rfr.length>0 &&
        [
            'https://worker.mturk.com',
            'https://workersandbox.mturk.com'
        ].some( (url) => (rfr.startsWith(url)) )
    )],

    ['private', true]
];

export const platformConfig = {
    ...configDefaults,
    ...importConfigs( conds.find((c) => (c[1]))[0] )
}
