var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-4" },
    [
      _c("v-slider", {
        attrs: {
          value: 1,
          readonly: "",
          "tick-size": "2",
          ticks: "always",
          "tick-labels": [1, 2, 3, 4, 5],
          min: "1",
          max: "5",
          color: "transparent",
        },
        scopedSlots: _vm._u([
          {
            key: "prepend",
            fn: function () {
              return [
                _c(
                  "div",
                  {
                    staticClass: "mt-9 text-right",
                    staticStyle: { width: "50px" },
                  },
                  [_vm._v(" Low ")]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "append",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "mt-9", staticStyle: { width: "50px" } },
                  [_vm._v(" High ")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("div", { staticClass: "d-flex" }, [
        _c("div", { staticStyle: { width: "61px" } }),
        _c(
          "div",
          { staticClass: "flex-grow-1" },
          [
            _c(
              "v-radio-group",
              {
                staticClass: "mt-2",
                attrs: { "hide-details": "", readonly: _vm.readonly },
                on: { change: _vm.emitEvents },
              },
              [
                _c(
                  "div",
                  { staticClass: "d-flex justify-space-between mx-1" },
                  [
                    _c("v-radio", { attrs: { value: 1 } }),
                    _c("v-radio", { attrs: { value: 2 } }),
                    _c("v-radio", { attrs: { value: 3 } }),
                    _c("v-radio", { attrs: { value: 4 } }),
                    _c("v-radio", { attrs: { value: 5 } }),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
        _c("div", { staticStyle: { width: "61px" } }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }