var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-navigation-drawer",
    {
      staticClass: "grey lighten-4 pt-4",
      attrs: { app: "", clipped: "", right: "", width: "400" },
    },
    [
      _c(
        "v-list",
        [
          _c(
            "v-list-item",
            { staticClass: "py-2" },
            [
              _c("v-autocomplete", {
                attrs: {
                  outlined: "",
                  dense: "",
                  "hide-details": "",
                  items: _vm.events,
                  "item-text": "label",
                  "item-value": "eid",
                  label: "Event",
                },
                model: {
                  value: _vm.eid,
                  callback: function ($$v) {
                    _vm.eid = $$v
                  },
                  expression: "eid",
                },
              }),
            ],
            1
          ),
          _c("v-list-item", [_vm._v("Requested JSON:")]),
          _c(
            "v-list-item",
            [
              _c("v-autocomplete", {
                attrs: {
                  outlined: "",
                  dense: "",
                  items: _vm.queryHistory,
                  disabled: _vm.queryHistory.length == 0,
                  placeholder:
                    _vm.queryHistory.length > 0
                      ? `Select from history ... (${_vm.queryHistory.length})`
                      : "No history found",
                },
                model: {
                  value: _vm.queryHistoryItem,
                  callback: function ($$v) {
                    _vm.queryHistoryItem = $$v
                  },
                  expression: "queryHistoryItem",
                },
              }),
            ],
            1
          ),
          _c(
            "v-list-item",
            [
              _c("codemirror", {
                attrs: { options: _vm.cmOptions, width: "100%" },
                model: {
                  value: _vm.query,
                  callback: function ($$v) {
                    _vm.query = $$v
                  },
                  expression: "query",
                },
              }),
            ],
            1
          ),
          _c(
            "v-list-item",
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: {
                        keydown: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.sendEvent.apply(null, arguments)
                        },
                        click: _vm.sendEvent,
                      },
                    },
                    [_vm._v(" Send ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }