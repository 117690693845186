<template>
    <div class="pa-4 mx-auto" style="max-width: 800px">
        <p class="text-h4 mt-4 text-center">Instruction</p>
        <ul>
            <li>You will work on 100+ trials of image evaluation.</li>
            <li>In each trial, you are given an image at the top.<br /></li>
            <li>
                Given two more images at the bottom, you are asked to answer
                which one is more similar to the image at the top.
            </li>
            <li>
                To answer, click on the image you think is more similar to the
                image at the top.
            </li>
            <li>
                Once you answer, the task will pause for 1 second, and then the
                next trial will start.
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    data: () => ({
        radioSliderValue: null
    })
}
</script>
<style>
span.caution {
    color: #c00;
}
</style>
