var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "pa-4",
      attrs: {
        elevation: "0",
        tile: "",
        color: "grey lighten-5",
        height: "100%",
      },
    },
    [
      _c("div", { staticClass: "overline mb-4" }, [_vm._v("ANSWER DATA")]),
      _c("vue-json-pretty", {
        staticStyle: { "line-height": "1.4em" },
        attrs: { data: _vm.response },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }