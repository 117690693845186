var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "fill-height mx-auto" },
    [
      _vm.pageState === "screening"
        ? _c("screening", {
            on: {
              confirm: (input) => {
                _vm.nano.ans.screeningWordInput = input
                _vm.pageState = "start"
              },
            },
          })
        : _vm._e(),
      _vm.pageState === "start"
        ? _c("start", {
            on: {
              confirm: function ($event) {
                _vm.pageState = "judgement"
              },
            },
          })
        : _vm._e(),
      _vm.pageState === "judgement" && _vm.imgSrcs !== null
        ? _c("judgement", {
            key: `trial-${_vm.trial}`,
            attrs: {
              "show-first-blank": _vm.trial !== 0,
              "img-srcs": _vm.imgSrcs,
            },
            on: { "image-select": _vm.saveAndNext },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }