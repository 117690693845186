<template>
    <div>
        <Instruction></Instruction>
        <div class="text-center mb-8">
            <v-btn large @click="submit()" class="mb-8"><v-icon left>mdi-check</v-icon>I understood above</v-btn>
        </div>
    </div>
</template>
<script>
import Instruction from './Base.vue'
import nanoMixIn from "@/mixins/nano";
export default {
    mixins: [nanoMixIn],
    components: { Instruction }
}
</script>