var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "fill-height d-flex flex-column" },
    [
      _c("progress-bar", {
        attrs: {
          count: _vm.nano.props.count,
          "total-count": _vm.nano.props.totalCount,
        },
      }),
      _c(
        "div",
        { staticClass: "flex-grow-1" },
        [
          !_vm.started ? _c("resource-load-progress") : _vm._e(),
          _vm.started
            ? _c(
                "div",
                {
                  staticClass:
                    "card ma-auto fill-height d-flex align-center flex-column",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "d-flex align-center flex-grow-1",
                      staticStyle: { width: "100%" },
                    },
                    [
                      _c(
                        "temporary-div",
                        {
                          staticClass: "fill-width text-center",
                          attrs: { "time-ranges": [_vm.timeRanges.img1] },
                        },
                        [
                          _c("img", {
                            staticStyle: { "max-width": "500px" },
                            attrs: { src: _vm.imgSrcs[0] },
                          }),
                        ]
                      ),
                      _c(
                        "temporary-div",
                        {
                          staticClass: "fill-width text-center",
                          attrs: { "time-ranges": [_vm.timeRanges.blank2] },
                          on: {
                            show: function ($event) {
                              _vm.imagining1 = true
                            },
                            hide: function ($event) {
                              _vm.imagining1 = false
                            },
                          },
                        },
                        [
                          _vm.imagining1
                            ? _c("imagine-pause-progress", {
                                attrs: {
                                  "total-seconds":
                                    (_vm.timeRanges.blank2[1] -
                                      _vm.timeRanges.blank2[0]) /
                                    1000,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "temporary-div",
                        {
                          staticClass: "fill-width text-center",
                          attrs: { "time-ranges": [_vm.timeRanges.img2] },
                        },
                        [
                          _c("img", {
                            staticStyle: { "max-width": "500px" },
                            attrs: { src: _vm.imgSrcs[1] },
                          }),
                        ]
                      ),
                      _c(
                        "temporary-div",
                        {
                          staticClass: "fill-width text-center",
                          attrs: { "time-ranges": [_vm.timeRanges.blank3] },
                          on: {
                            show: function ($event) {
                              _vm.imagining2 = true
                            },
                            hide: function ($event) {
                              _vm.imagining2 = false
                            },
                          },
                        },
                        [
                          _vm.imagining2
                            ? _c("imagine-pause-progress", {
                                attrs: {
                                  "total-seconds":
                                    (_vm.timeRanges.blank3[1] -
                                      _vm.timeRanges.blank3[0]) /
                                    1000,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "temporary-div",
                        {
                          staticClass: "fill-width text-center",
                          attrs: { "time-ranges": [_vm.timeRanges.choices] },
                          on: {
                            show: function ($event) {
                              _vm.startTime = Date.now()
                            },
                          },
                        },
                        [
                          _c(
                            "p",
                            { staticClass: "text-h6 font-weight-medium my-4" },
                            [_vm._v(_vm._s(_vm.question))]
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex justify-space-between" },
                            _vm._l([0, 1], function (i) {
                              return _c(
                                "v-card",
                                {
                                  key: `button-${i}`,
                                  staticClass: "ma-4 text-center",
                                  attrs: { width: "300" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.submitAnswer(i + 1)
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    staticClass: "pa-4",
                                    staticStyle: { width: "100%" },
                                    attrs: { src: _vm.imgSrcs[i] },
                                  }),
                                  _c(
                                    "p",
                                    {
                                      staticClass: "text-body font-weight-bold",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(i === 0 ? "First" : "Second") +
                                          " one"
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }