<template>
    <div class="pa-4 mx-auto" style="max-width: 800px">
        <p class="text-h4">About This HIT</p>
        <ul>
            <li>You will be asked simple questions to evaluate 30 pictures in a row.</li>
            <li>Estimated time: 10 minutes.</li>
        </ul>
        <p class="text-h4 mt-4">Instruction</p>
        <ol>
            <li>
                Click the 
                <v-btn large color="primary" class="mx-2">START</v-btn>
                 button to show a picture.
            </li>
            <li>
                Look at the picture for 4 seconds. Below is an example:<br>
                <img src="/static/202212/sample/img1-1.png" style="width:300px" /><br>
            </li>
            <li>
                When the image disappears, imagine the picture in your mind for 8 seconds<br>(A blank page is shown in this period).<br>
            </li>
            <li>
                Answer how accurate and vivid you could imagine it.
                <radio-slider></radio-slider>
            </li>
        </ol>
    </div>
</template>
<script>
import RadioSlider from '../../ui/RadioSlider.vue';
export default {
    components: { RadioSlider },
    data: () => ({
        radioSliderValue: null
    })
}
</script>
<style>
    span.caution { color: #c00; }
</style>