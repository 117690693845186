function getTemplateRequirePath(prjName, tmplName) {
    return ['[Instruction]', '[Preview]'].includes(tmplName)
        ? `projects/${prjName}/templates/${tmplName.substring(1, tmplName.length-1)}`
        : `projects/${prjName}/templates/${tmplName}/Main`;
}

function loadTemplateHTML(requirePath) {
    try { return require(`@/${requirePath}.html`); }
    catch (e) {
        return null;
    }
}

function loadTemplateComponent(requirePath) {
    try { return require(`@/${requirePath}.vue`).default; }
    catch (e) {
        console.error(e);
        return null;
    }
}

export default function loadTemplate(prjName, tmplName) {
    const requirePath = getTemplateRequirePath(prjName, tmplName);
    return {
        requirePath,
        tmplComponent: loadTemplateComponent(requirePath),
        tmplHTML: loadTemplateHTML(requirePath),
    };
}
