<template>
    <div class="d-flex flex-column">
        <v-progress-circular
            class="ma-auto"
            :rotate="-90"
            :size="100"
            :width="15"
            color="primary"
            :value="((100/totalSeconds)*(secondsElapsed))"
        >{{ ( secondsElapsed ) }}</v-progress-circular>
        <p class="py-4">Now re-imagine what you just saw...</p>
    </div>  
</template>
<script>
export default {
    data: () => ({
        interval: null,
        secondsElapsed: 1,
    }),
    props: ['totalSeconds'],
    mounted() {
        this.secondsElapsed = 1;
        this.interval = setInterval(() => {
            this.secondsElapsed += 1;
        }, 1000);
    },
    destroyed() {
        clearInterval(this.interval);
    }
}
</script>