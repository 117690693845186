<template>
    <div class="d-flex align-center justify-center fill-height fill-width">
        <button
            :style="buttonStyle"
            @click="
                (e) => {
                    $emit('confirm', e)
                }
            "
        >
            Start
        </button>
    </div>
</template>
<script>
export default {
    data: () => ({
        buttonStyle: {
            width: '300px',
            height: '100px',
            fontSize: '30px',
            backgroundColor: 'black',
            color: 'white',
            borderRadius: '10px'
        }
    })
}
</script>
