<template>
    <div v-if="count !== null && totalCount !== null" class="text-center mx-auto" style="width:80%;">
        <!-- <v-progress-linear
            :value="count/totalCount*100"
            color="primary"
            height="5"
        ></v-progress-linear> -->
        <p class="text-h6 font-weight-medium my-4">Task {{ count+1 }} / {{ totalCount }}</p>
    </div>
</template>
<script>
export default {
    props: {
        count: { default: null, type: Number },
        totalCount: { default: null, type: Number },
    }
}
</script>