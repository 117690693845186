var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "d-flex align-center justify-center fill-height fill-width",
    },
    [
      _c(
        "button",
        {
          style: _vm.buttonStyle,
          on: {
            click: (e) => {
              _vm.$emit("confirm", e)
            },
          },
        },
        [_vm._v(" Start ")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }