<template>
    <v-app>
        <wp-login-button
            v-if="!schemeConfig.Anonymous"
            :projectName="projectName"
            :showWorkerMenu="showWorkerMenu"
            :platformWorkerId="platformWorkerId"
            />

        <div class="d-flex flex-column" style="height: 100%">
            <wp-header
                :project-name="projectName"
                :schemeConfig="schemeConfig"
                ref="header"
            ></wp-header>

            <div class="flex-grow-1">
                <v-overlay color="white" :opacity="0.6" absolute :value="loadingNextTemplate">
                    <v-progress-circular color="grey" indeterminate size="64"></v-progress-circular>
                </v-overlay>
                <div class="d-flex flex-column" style="height: 100%">
                    <div v-if="schemeConfig.PageNavigation">
                        <v-col class="text-center">
                            <v-btn
                                color="white"
                                class="mx-4 pa-2"
                                @click="getTemplate('PREV')"
                                :disabled="!hasPrevTemplate">
                                <v-icon>mdi-chevron-left</v-icon>
                            </v-btn>

                            <v-btn
                                color="white"
                                class="mx-4 pa-2"
                                @click="getTemplate('NEXT')"
                                :disabled="!hasNextTemplate">
                                <v-icon>mdi-chevron-right</v-icon>
                            </v-btn>
                        </v-col>
                    </div>
                    <div class="flex-grow-1">
                        <template v-if="showTemplate">
                            <v-slide-x-reverse-transition hide-on-leave>
                                <component
                                    v-show="tmplComponent"
                                    :key="'tmplComponent'+componentCounter"
                                    :is="tmplComponent"
                                    :nano-props="nanoProps"
                                    :prev-answer="prevAnswer"
                                    :worker-id="workerId"
                                    :nano-mix-in-file-upload-queue.sync="nanoMixInFileUploadQueue"
                                    @upload-file="uploadFile"
                                    @submit="submit"
                                ></component>
                            </v-slide-x-reverse-transition>

                            <v-slide-x-reverse-transition hide-on-leave>
                                <iframe
                                    v-show="tmplHTML"
                                    :srcdoc="tmplHTML"
                                    ref="iframe"
                                    style="width:100%;border:0;"
                                    />
                            </v-slide-x-reverse-transition>
                        </template>

                        <template v-if="!showTemplate && showPreviewTemplate">
                            <v-btn
                                v-if="schemeConfig.Anonymous"
                                color="indigo"
                                @click="anonymousLogin();">
                                Start Task
                            </v-btn>
                            <component :is="previewTemplate" />
                        </template>
                    </div>
                </div>
            </div>
        </div>


        <tutti-dialog ref="dialogAdviseReturn" maxWidth="800"
            :buttons="[
                { label: 'OK', attrs: { color: 'grey darken-1', text: true }, on: { click() { $refs.dialogAdviseReturn.hide() } } }
            ]">
            <template #title>
                <v-icon color="warning" class="mr-2">mdi-alert</v-icon> No more task is currently available
            </template>
            <template #body>
                <v-card-text>
                    Thank you for your interest in this HIT! We are sorry but there is no more task available for you on this HIT for now.<br>
                    Please return this HIT (nothing else will happen while this page is open).
                </v-card-text>
            </template>
        </tutti-dialog>

        <tutti-dialog persistent ref="dialogUnskippableNode" maxWidth="800"
            :buttons="[
                { label: 'OK', attrs: { color: 'success', dark: true }, on: { click: _onSubmitWorkSession } }
            ]">
            <template v-slot:title>
                <v-icon color="success" class="mr-2">mdi-check-circle</v-icon> You reached the end of this HIT
            </template>
            <template v-slot:body>
                <v-card-text>
                    You reached the end of this task now; it might have been earlier than expected, but don't worry, you will still earn the same amount of reward.<br>
                    This HIT will be automatically submitted as you close this dialog.
                </v-card-text>
            </template>
        </tutti-dialog>

        <tutti-dialog ref="dialogSessionError" maxWidth="500"
            :buttons="[
                { label: 'OK', attrs: { color: 'grey darken-1', text: true }, on: { click() { $refs.dialogSessionError.hide(); } } }
            ]">
            <template v-slot:title>
                <v-icon color="warning" class="mr-2">mdi-alert</v-icon> Please do one HIT at a time
            </template>
            <template v-slot:body>
                <v-card-text>
                    Multiple concurrent sessions are not allowed for this HIT. Please finish the other HIT first.<br>
                    If you believe this is caused in error, please try again later or contact <a href="mailto:mturk04@pcl.cs.waseda.ac.jp">mturk04@pcl.cs.waseda.ac.jp</a>.
                </v-card-text>
            </template>
        </tutti-dialog>
                
        <tutti-dialog persistent ref="dialogCompleted" maxWidth="500"
            :buttons="[
                { label: 'OK', attrs: { color: 'indigo darken-w', dark: true }, on: { click: _onSubmitWorkSession } }
            ]">
            <template v-slot:title>
                <v-icon color="success" class="mr-2">mdi-check-circle</v-icon> Task Completed!
            </template>
            <template v-slot:body>
                <v-card-text>
                    You finished all of our questions. Thank you for your contribution!
                </v-card-text>
            </template>
        </tutti-dialog>

    </v-app>
</template>

<script>
import { TuttiClient } from '@iflb/tutti-client'
import { platformConfig } from '@/lib/platformConfig'
import TuttiDialog from '@/components/ui/TuttiDialog'
import WorkPlaceLoginButton from '@/components/views/WorkPlaceLoginButton'
import WorkPlaceHeader from '@/components/views/WorkPlaceHeader'
import loadTemplate from '@/lib/loadTemplate.js'

export default {
    components: {
        TuttiDialog,
        "wp-login-button": WorkPlaceLoginButton,
        "wp-header": WorkPlaceHeader
    },
    data: () => ({
        client: new TuttiClient(true),
        wsdPath: "/ducts/wsd",

        schemeConfig: {
            InstructionBtn: true,
        },

        showTemplate: false,
        showPreviewTemplate: false,
        loadingNextTemplate: false,
        tmplName: "",
        wsid: null,
        nsid: "",
        answer: {},
        nanoProps: null,
        workerId: "",
        platformWorkerId: "",
        prevAnswer: null,
        tmplComponent: null,
        tmplHTML: null,
        componentCounter: -1,
        oneTimeToken: null,
        nanoMixInFileUploadQueue: [],

        hasPrevTemplate: false,
        hasNextTemplate: false,
    }),
    computed: {
        previewTemplate() {
            try { return require(`@/projects/${this.projectName}/templates/Preview.vue`).default }
            catch { return null }
        },
        showWorkerMenu() {
            return platformConfig && platformConfig.showWorkerMenu;
        },
    },
    props: ["projectName"],
    methods: {
        getTemplate(direction) {
            if(this.wsid) {
                this.loadingNextTemplate = true;
                this.client._duct.send(
                    this.client._duct.nextRid(),
                    this.client._duct.EVENT.WORKPLACE_GET_NEXT_TEMPLATE,
                    {
                        target: direction,
                        work_session_id: this.wsid,
                        node_session_id: this.nsid
                    }
                );
            }
        },
        async uploadFile(file, path, index) {
            const paths = await this.client.resource.saveFilesInWorkSession({
                one_time_token: this.oneTimeToken,
                current_path: path,
                files: [file]
            });
            this.nanoMixInFileUploadQueue[index].savedPath = paths[0];
            this.nanoMixInFileUploadQueue[index].status = 'complete';
        },
        async submit($event) {
            Object.assign(this.answer, $event);
            try {
                await this.client._duct.call(
                    this.client._duct.EVENT.WORKPLACE_SET_RESPONSE,
                    {
                        work_session_id: this.wsid,
                        node_session_id: this.nsid,
                        automation_parameter_set_id: platformConfig.automationParameterSetId(),
                        platform_parameter_set_id: platformConfig.platformParameterSetId(),
                        answers: this.answer
                    }
                );
                this.answer = {}
                this.getTemplate("NEXT");
            } catch(e) {
                alert('saving response failed!');
                console.error(e);
            }
        },
        _onSubmitWorkSession() {
            platformConfig.onSubmitWorkSession(this);
        },
       
        anonymousLogin() {
            localStorage.setItem("tuttiPlatformWorkerId", "__ANONYMOUS__"+this.getDuctSSID());
            window.location.reload();
        },
        getDuctSSID() {
            let splitUrl = this.client._duct.WSD.websocket_url.split("/");
            return splitUrl[splitUrl.length-1].split(".")[0];
        },
        loadTemplate(props) {
            return new Promise( (resolve) => {
                this.tmplComponent = null;
                this.tmplHTML = null;
                if(this.projectName && this.tmplName) {
                    const { tmplComponent, tmplHTML } = loadTemplate(this.projectName, this.tmplName);
                    if (tmplComponent) {
                        this.tmplComponent = tmplComponent;
                    } else if (tmplHTML) {
                        const module = require(`@/lib/nano.js`).default;
                        let iframe = this.$refs.iframe;
                        iframe.addEventListener('load', () => {
                            iframe.contentWindow.nano = module;
                            iframe.contentWindow.nano._loadProps(props);
                            iframe.contentWindow.dispatchEvent(new CustomEvent('nanoLoad'));
                            iframe.style.height = iframe.contentWindow.document.documentElement.scrollHeight + 'px';
                        });
                        this.tmplHTML = tmplHTML;
                    } else {
                        alert('no template was found');
                    }
                }
                resolve();
            });
        },
        pushInstructionDialogIfNeeded() {
            this.$refs.header.dialogInstruction.shown = this.schemeConfig.PushInstruction;
        }
    },
    mounted: function(){
        window.addEventListener('message', (e) => {
            if(e.data.tutti){
                switch (e.data.tutti.event){
                    case 'submit':
                        this.submit(e.data.tutti.data);
                        break;
                }
            }
        });
        platformConfig.invokeWhenReady(() => {
            this.clientToken = platformConfig.clientToken(this);
            if(this.clientToken) {
                this.client.invokeOnOpen(async () => {
                    this.client.resource.on('saveFilesInWorkSession', {
                        success: this.saveFileHandler
                    });
                    this.client._duct.setEventHandler(
                        this.client._duct.EVENT.WORKPLACE_GET_NEXT_TEMPLATE,
                        (rid, eid, data) => {
                            if(data.terminated) {
                                if(this.tmplName=='') {
                                    this.$refs.dialogAdviseReturn.shown = true;
                                } else if(data.terminate_reason=='unskippable-node') {
                                    this.$refs.dialogUnskippableNode.shown = true;
                                } else if(data.terminate_reason=='session-end') {
                                    if(this.schemeConfig.CompletionAlert){
                                        console.log('session end..');
                                        this.$refs.dialogCompleted.show();
                                    }
                                    else  this._onSubmitWorkSession(this);
                                }
                                else  this._onSubmitWorkSession(this);
                            } else if(data.template_name) {
                                this.showTemplate = false;
                                this.tmplHTML = null;
                                this.tmplName = '';
                                this.$nextTick(async () => {
                                    this.tmplName = data.template_name;
                                    this.nsid = data.node_session_id;

                                    this.$set(this, 'nanoProps', data.props);
                                    await this.loadTemplate(data.props);
                                    this.componentCounter++;

                                    if('answers' in data) {
                                        this.$set(this, 'prevAnswer', data.answers);
                                    }
                                    this.showTemplate = true;
                                });
                                this.hasPrevTemplate = data.has_prev_template;
                                this.hasNextTemplate = data.has_next_template;
                            }
                            this.loadingNextTemplate = false;
                        }
                    );

                    try {
                        this.platformWorkerId = platformConfig.workerId(this);
                        const data = await this.client._duct.call(
                                this.client._duct.EVENT.PROJECT_GET_SCHEME_FOR_WORKPLACE,
                                { project_name: this.projectName }
                            );
                        let scheme = data.content;
                        this.$set(this, 'schemeConfig', scheme.config);
                        if(this.platformWorkerId) {
                            try {
                                const data = await this.client._duct.call(
                                        this.client._duct.EVENT.WORKPLACE_START,
                                        {
                                            project_name: this.projectName,
                                            client_token: this.clientToken,
                                            platform: platformConfig.platformName(this),
                                            platform_worker_id: this.platformWorkerId,
                                            nanotask_group_ids: platformConfig.nanotaskGroupIds(this) || [],
                                        }
                                    );
                                this.workerId = data.worker_id;
                                if(data.is_new_worker || data.is_new_worker_for_project) {
                                    this.pushInstructionDialogIfNeeded();
                                }
                                this.wsid = data["work_session_id"];
                                this.oneTimeToken = data['one_time_token'];
                                this.getTemplate("NEXT");
                            } catch (e) {
                                if (e.code==this.client.ERROR.WORKPLACE_START.FORBIDDEN_PARALLEL_SESSION) {
                                    this.$refs.dialogSessionError.shown = true;
                                }
                                console.log(e);
                            }
                        } else if(this.schemeConfig.Preview) {
                            this.showPreviewTemplate = true;
                            this.pushInstructionDialogIfNeeded();
                        } else {
                            this.anonymousLogin();
                        }
                    } catch (e) {
                        alert("Error occured; please kindly report this to us!\n==\n" + e.message);
                        console.error(e);
                    }
                });
                this.client.open(this.wsdPath);
            }
        });
    }
}
</script>
