var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { attrs: { id: "inspire" } },
    [
      _c(
        "v-main",
        [
          _c(
            "v-container",
            { staticClass: "fill-height", attrs: { fluid: "" } },
            [
              _c(
                "v-row",
                { attrs: { align: "center", justify: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "8", md: "4" } },
                    [
                      _c(
                        "v-card",
                        { staticClass: "elevation-12" },
                        [
                          _c(
                            "v-toolbar",
                            { attrs: { color: "indigo", dark: "", flat: "" } },
                            [
                              _c("v-toolbar-title", [
                                _vm._v("Enter your worker ID"),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "v-form",
                                {
                                  ref: "form",
                                  on: {
                                    submit: function ($event) {
                                      $event.preventDefault()
                                      return _vm.mySubmit.apply(null, arguments)
                                    },
                                  },
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Worker ID",
                                      "prepend-icon": "mdi-account",
                                      type: "text",
                                      rules: _vm.workerIdRules,
                                    },
                                    model: {
                                      value: _vm.workerId,
                                      callback: function ($$v) {
                                        _vm.workerId = $$v
                                      },
                                      expression: "workerId",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-card-actions",
                            [
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "indigo", dark: "" },
                                  on: { click: _vm.mySubmit },
                                },
                                [_vm._v("Log in")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }