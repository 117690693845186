var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "fill-height d-flex flex-column" },
    [
      _c("progress-bar", {
        attrs: {
          count: _vm.nano.props.count,
          "total-count": _vm.nano.props.totalCount,
        },
      }),
      _c("div", { staticClass: "fill-height fill-width d-flex align-center" }, [
        _c(
          "div",
          { staticClass: "ma-auto text-center" },
          [
            _c("p", { staticClass: "text-h6 font-weight-medium my-4" }, [
              _vm._v(_vm._s(_vm.nano.props.quiz)),
            ]),
            _c(
              "v-radio-group",
              {
                staticClass: "radio-group mx-auto",
                attrs: { readonly: _vm.readonly },
                on: {
                  change: function ($event) {
                    _vm.readonly = true
                    _vm.submitAfterAWhile()
                  },
                },
                model: {
                  value: _vm.nano.ans.answer,
                  callback: function ($$v) {
                    _vm.$set(_vm.nano.ans, "answer", $$v)
                  },
                  expression: "nano.ans.answer",
                },
              },
              [
                _c("v-radio", { attrs: { value: "Yes", label: "Yes" } }),
                _c("v-radio", { attrs: { value: "No", label: "No" } }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }