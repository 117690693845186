<template>
    <v-main class="grey lighten-4">
        <v-card tile class="pa-3 ma-10">
            <v-card-title>
                {{ $t('console.versionLogs.title') }}
            </v-card-title>
            <v-simple-table>
                <tbody>
                    <tr>
                        <th>{{ $t('console.versionLogs.columns.version') }}</th>
                        <th>{{ $t('console.versionLogs.columns.dateAndTime') }}</th>
                        <th>{{ $t('console.versionLogs.columns.details') }}</th>
                    </tr>
                    <tr v-for="log in logs" :key="log.version">
                        <td>{{ log.version }}</td>
                        <td>{{ log.date }}</td>
                        <td class="py-2">
                            <div v-for="key in Object.keys(log.messages)" :key="`${log.version}-${key}`">
                                <v-chip dark small :color="logTypes[key].color" v-text="logTypes[key].label" class="my-1"></v-chip>
                                <ul>
                                    <li v-for="msg,i in log.messages[key]" :key="`${log.version}-${key}-${i}`" v-html="msg"></li>
                                </ul>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </v-simple-table>
        </v-card>
    </v-main>
</template>

<script>
import { logs } from '@/lib/version'

export default {
    data() {
        return {
            logs,
            logTypes: {
                feature: {
                    label: this.$t('console.versionLogs.categoryChips.feature'),
                    color: 'lime darken-1',
                },
                bugfix: {
                    label: this.$t('console.versionLogs.categoryChips.bugfix'),
                    color: 'red',
                },
                improvement: {
                    label: this.$t('console.versionLogs.categoryChips.improvement'),
                    color: 'cyan'
                }
            }
        }
    }
}
</script>
