let defaultProps = {};
let props = {};

function _emit(event, data) {
    let message = { tutti: { event } };
    if(data) message.tutti.data = data;
    window.parent.postMessage(message);
}
function submit(data) {
    if(typeof data === 'object'){
        _emit('submit', data);
    } else {
        console.error('Submitted data must be a javascript object');
    }
}
function _loadProps(data) {
    props = data;
}
function setDefaultProps(data) {
    defaultProps = data;
}
function applyProps(callback) {
    const _props = Object.keys(props).length>0 ? JSON.parse(JSON.stringify(props)) : defaultProps;
    callback(_props);
}

export default {
    _loadProps,
    submit,
    setDefaultProps,
    applyProps
}
