import { render, staticRenderFns } from "./Top.vue?vue&type=template&id=df631872&"
import script from "./Top.vue?vue&type=script&lang=js&"
export * from "./Top.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('df631872')) {
      api.createRecord('df631872', component.options)
    } else {
      api.reload('df631872', component.options)
    }
    module.hot.accept("./Top.vue?vue&type=template&id=df631872&", function () {
      api.rerender('df631872', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/pages/Console/Platforms/TuttiMarket/dump/Top.vue"
export default component.exports