var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    [
      _c("query-nav-bar", {
        attrs: { client: _vm.client, events: _vm.events },
      }),
      _c("query-log-table", { attrs: { logs: _vm.queryLogs } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }