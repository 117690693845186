var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pa-4 mx-auto", staticStyle: { "max-width": "800px" } },
    [
      _c("p", { staticClass: "text-h4" }, [_vm._v("About This HIT")]),
      _vm._m(0),
      _c("p", { staticClass: "text-h4 mt-4" }, [_vm._v("Instruction")]),
      _c("ol", [
        _c(
          "li",
          [
            _vm._v(" Click the "),
            _c(
              "v-btn",
              { staticClass: "mx-2", attrs: { large: "", color: "primary" } },
              [_vm._v("START")]
            ),
            _vm._v(" button to show a picture. "),
          ],
          1
        ),
        _vm._m(1),
        _vm._m(2),
        _c(
          "li",
          [
            _vm._v(" Answer how accurate and vivid you could imagine it. "),
            _c("radio-slider"),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", [
      _c("li", [
        _vm._v(
          "You will be asked simple questions to evaluate 30 pictures in a row."
        ),
      ]),
      _c("li", [_vm._v("Estimated time: 10 minutes.")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _vm._v(" Look at the picture for 4 seconds. Below is an example:"),
      _c("br"),
      _c("img", {
        staticStyle: { width: "300px" },
        attrs: { src: "/static/202212/sample/img1-1.png" },
      }),
      _c("br"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _vm._v(
        " When the image disappears, imagine the picture in your mind for 8 seconds"
      ),
      _c("br"),
      _vm._v("(A blank page is shown in this period)."),
      _c("br"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }