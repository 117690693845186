var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "fill-height d-flex flex-column align-center justify-center",
    },
    [
      _c(
        "temporary-div",
        {
          staticStyle: { width: "100%" },
          attrs: { timeRanges: [[_vm.firstBlankMilliSeconds]] },
          on: { show: _vm.startTimer },
        },
        [
          _c("div", { staticClass: "pa-4" }, [
            _c("img", {
              staticClass: "d-block image-size mx-auto",
              attrs: { src: _vm.imgSrcs[0][1] },
            }),
          ]),
          _c(
            "v-card",
            {
              staticClass: "mx-auto px-6",
              staticStyle: { width: "min-content" },
            },
            [
              _c("v-card-text", [
                _c("p", { staticClass: "text-h4 text-center black--text" }, [
                  _vm._v("Answer"),
                ]),
                _c(
                  "div",
                  { staticClass: "d-flex align-center justify-center" },
                  [
                    _c("img", {
                      staticClass: "pa-2 image-size",
                      staticStyle: { cursor: "pointer" },
                      attrs: { src: _vm.imgSrcs[1][1] },
                      on: {
                        click: function ($event) {
                          _vm.selectedPos = 0
                          _vm.stopTimer()
                        },
                      },
                    }),
                    _c("p", { staticClass: "text-h5 px-4 black--text" }, [
                      _vm._v("or"),
                    ]),
                    _c("img", {
                      staticClass: "pa-2 image-size",
                      staticStyle: { cursor: "pointer" },
                      attrs: { src: _vm.imgSrcs[2][1] },
                      on: {
                        click: function ($event) {
                          _vm.selectedPos = 1
                          _vm.stopTimer()
                        },
                      },
                    }),
                  ]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }