var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.count !== null && _vm.totalCount !== null
    ? _c(
        "div",
        { staticClass: "text-center mx-auto", staticStyle: { width: "80%" } },
        [
          _c("p", { staticClass: "text-h6 font-weight-medium my-4" }, [
            _vm._v(
              "Task " + _vm._s(_vm.count + 1) + " / " + _vm._s(_vm.totalCount)
            ),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }