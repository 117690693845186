var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pa-4 mx-auto", staticStyle: { "max-width": "800px" } },
    [
      _c("p", { staticClass: "text-h4" }, [_vm._v("About This HIT")]),
      _vm._m(0),
      _c("p", { staticClass: "text-h4 mt-4" }, [_vm._v("Instruction")]),
      _c("ol", [
        _c("li", [_vm._v("A blank screen is shown for 2 seconds.")]),
        _vm._m(1),
        _vm._m(2),
        _vm._m(3),
        _vm._m(4),
        _c("li", [
          _vm._v(" Answer which picture was more accurately imagined. "),
          _c(
            "div",
            { staticClass: "d-flex justify-space-between" },
            [
              _c(
                "v-card",
                {
                  staticClass: "ma-4 text-center",
                  attrs: { width: "300" },
                  on: {
                    click: function ($event) {
                      _vm.radioValue = 1
                    },
                  },
                },
                [
                  _c("img", {
                    staticClass: "pa-4",
                    staticStyle: { width: "100%" },
                    attrs: { src: "/static/202212/sample/img1-1.png" },
                  }),
                  _c("p", { staticClass: "text-body font-weight-bold" }, [
                    _vm._v("First one"),
                  ]),
                ]
              ),
              _c(
                "v-card",
                {
                  staticClass: "ma-4 text-center",
                  attrs: { width: "300" },
                  on: {
                    click: function ($event) {
                      _vm.radioValue = 2
                    },
                  },
                },
                [
                  _c("img", {
                    staticClass: "pa-4",
                    staticStyle: { width: "100%" },
                    attrs: { src: "/static/202212/sample/img2-1.png" },
                  }),
                  _c("p", { staticClass: "text-body font-weight-bold" }, [
                    _vm._v("Second one"),
                  ]),
                ]
              ),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", [
      _c("li", [
        _vm._v(
          "You will be asked simple questions to evaluate 15 pairs of pictures in a row."
        ),
      ]),
      _c("li", [_vm._v("Estimated time: 10 minutes.")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _vm._v(
        " Look at a suggested picture for 2 seconds, as shown like below:"
      ),
      _c("br"),
      _c("img", {
        staticStyle: { width: "100px" },
        attrs: { src: "/static/202212/sample/img1-1.png" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _vm._v(" Given 8 seconds, try to re-imagine the picture in your mind."),
      _c("br"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _vm._v(" Look at a different picture for 2 seconds. For example:"),
      _c("br"),
      _c("img", {
        staticStyle: { width: "100px" },
        attrs: { src: "/static/202212/sample/img2-1.png" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _vm._v(" Given 8 seconds, try to re-imagine the picture in your mind."),
      _c("br"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }