var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "fill-height d-flex flex-column" },
    [
      _c("progress-bar", {
        attrs: {
          count: _vm.nano.props.count,
          "total-count": _vm.nano.props.totalCount,
        },
      }),
      _c(
        "div",
        { staticClass: "flex-grow-1" },
        [
          !_vm.loaded ? _c("resource-load-progress") : _vm._e(),
          _vm.loaded && !_vm.started
            ? _c(
                "div",
                {
                  staticClass:
                    "fill-height d-flex flex-column align-center justify-center",
                },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { large: "", color: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.started = true
                        },
                      },
                    },
                    [_vm._v("Start")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.started
            ? _c(
                "div",
                {
                  staticClass:
                    "card ma-auto fill-height d-flex align-center flex-column",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "d-flex align-center flex-grow-1",
                      staticStyle: { width: "100%" },
                    },
                    [
                      _c(
                        "temporary-div",
                        {
                          staticClass: "fill-width text-center",
                          attrs: { "time-ranges": [_vm.timeRanges.img] },
                        },
                        [
                          _c("img", {
                            staticStyle: { "max-width": "300px" },
                            attrs: { src: _vm.imgSrc },
                          }),
                        ]
                      ),
                      _c("temporary-div", {
                        staticClass: "fill-width text-center",
                        attrs: { "time-ranges": [_vm.timeRanges.blank] },
                        on: {
                          show: function ($event) {
                            _vm.imagining = true
                          },
                        },
                      }),
                      _c(
                        "temporary-div",
                        {
                          staticClass: "fill-width text-center",
                          attrs: { "time-ranges": [_vm.timeRanges.choices] },
                          on: {
                            show: function ($event) {
                              _vm.startTime = Date.now()
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticStyle: { "max-width": "300px" },
                            attrs: { src: _vm.imgSrc },
                          }),
                          _c("radio-slider", {
                            attrs: { readonly: _vm.sliderReadOnly },
                            on: {
                              change: function ($event) {
                                return _vm.delayedSubmit()
                              },
                            },
                            model: {
                              value: _vm.nano.ans.answer,
                              callback: function ($$v) {
                                _vm.$set(_vm.nano.ans, "answer", $$v)
                              },
                              expression: "nano.ans.answer",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }