var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "500" },
      model: {
        value: _vm.shown,
        callback: function ($$v) {
          _vm.shown = $$v
        },
        expression: "shown",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v(" Manage NumAssignable ")]),
          _c(
            "v-card-text",
            [
              _c("v-select", {
                attrs: { items: _vm.methods },
                model: {
                  value: _vm.input.method,
                  callback: function ($$v) {
                    _vm.$set(_vm.input, "method", $$v)
                  },
                  expression: "input.method",
                },
              }),
              _c("v-text-field", {
                attrs: { type: "number", step: "1" },
                model: {
                  value: _vm.input.value,
                  callback: function ($$v) {
                    _vm.$set(_vm.input, "value", _vm._n($$v))
                  },
                  expression: "input.value",
                },
              }),
            ],
            1
          ),
          _c("tutti-dialog-actions", { attrs: { actions: _vm.actions } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }