<template>
    <div class="text-right ma-6">
        <v-menu
            offset-y
            v-if="showWorkerMenu && platformWorkerId">
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    text
                    color="indigo"
                    class="text-none"
                    v-bind="attrs"
                    v-on="on">
                    Worker ID: {{ platformWorkerId }}
                </v-btn>
            </template>
            <v-list>
                <v-list-item
                    key="logout"
                    @click="$refs.dialog.show()">
                    <v-list-item-title>Log out</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
        <v-btn
            v-if="showWorkerMenu && !platformWorkerId"
            dark
            color="indigo"
            :href="`../workplace-login?project=${projectName}`">
            Log in
        </v-btn>

        <tutti-dialog ref="dialog" maxWidth="500"
            :buttons="[
                { label: 'Cancel', attrs: { color: 'grey darken-1', text: true } },
                { label: 'Logout', attrs: { color: 'indigo darken-1', text: true }, on: { click: logout } }
            ]">
            <template #title>
                Are you sure to log out?
            </template>
            <template v-slot:body>
                <v-card-text>
                    Some of your working history may not be saved.
                </v-card-text>
            </template>
        </tutti-dialog>
    </div>
</template>

<script>
import TuttiDialog from '@/components/ui/TuttiDialog'
export default {
    components: {
        TuttiDialog
    },
    props: ["projectName", "showWorkerMenu", "platformWorkerId"],
    methods: {
        logout() {
            localStorage.removeItem("tuttiPlatformWorkerId");
            //this.platformWorkerId = "";
            window.location.reload();
        },
    }
}
</script>
