var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c(
        "v-main",
        [
          _c("v-data-table", {
            attrs: {
              headers: _vm.headers,
              items: _vm.logs,
              "items-per-page": 10,
              "disable-sort": "",
              "hide-default-footer": "",
            },
            scopedSlots: _vm._u([
              {
                key: "item.msg",
                fn: function ({ item }) {
                  return [
                    _c(
                      "v-icon",
                      {
                        attrs: {
                          small: "",
                          color: _vm.getIconColorForLog(item),
                        },
                      },
                      [_vm._v(_vm._s(_vm.getIconForLog(item)))]
                    ),
                    _c("b", [_vm._v(" " + _vm._s(item.label))]),
                    item.received[0]
                      ? _c("div", { staticStyle: { width: "100%" } }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.dateFormat(
                                  parseInt(item.sent.timestamp.getTime()),
                                  "yyyy-mm-dd HH:MM:ss"
                                )
                              ) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                    _c("vue-json-pretty", {
                      attrs: {
                        data: _vm.removeAccessTokenFromSentContent(
                          item.sent.content
                        ),
                        deep: 1,
                      },
                    }),
                    _vm._l(item.received, function (received, i) {
                      return _c(
                        "div",
                        {
                          key: `received${item.id}-${i}`,
                          staticStyle: { display: "flex" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-right": "5px",
                                width: "100%",
                              },
                            },
                            [
                              _c("v-icon", { attrs: { "x-small": "" } }, [
                                _vm._v("mdi-arrow-right"),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticStyle: { width: "100%" } },
                            [
                              _c("vue-json-pretty", {
                                attrs: { data: received.content, deep: 0 },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    }),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }