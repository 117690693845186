var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "pa-4 mx-auto", staticStyle: { "max-width": "800px" } },
      [
        _c("p", { staticClass: "text-h4 mt-4 text-center" }, [
          _vm._v("Instruction"),
        ]),
        _c("ul", [
          _c("li", [
            _vm._v("You will work on 100+ trials of image evaluation."),
          ]),
          _c("li", [
            _vm._v("In each trial, you are given an image at the top."),
            _c("br"),
          ]),
          _c("li", [
            _vm._v(
              " Given two more images at the bottom, you are asked to answer which one is more similar to the image at the top. "
            ),
          ]),
          _c("li", [
            _vm._v(
              " To answer, click on the image you think is more similar to the image at the top. "
            ),
          ]),
          _c("li", [
            _vm._v(
              " Once you answer, the task will pause for 1 second, and then the next trial will start. "
            ),
          ]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }