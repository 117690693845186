var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.shown,
          expression: "shown",
        },
      ],
    },
    [_vm._t("default", null, null, { show: _vm.show, hide: _vm.hide })],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }